import { InputViewModeEnum } from '../input-components/components/bs-ng-components/bs-ng-dropdown/InputViewModeEnum';
import { FieldOptions } from './FieldOptions';

export class FieldOptionsBuilder extends FieldOptions {
  private constructor(options: any) {
    super(options);
  }

  public static init(): FieldOptionsBuilder {
    return new FieldOptionsBuilder({});
  }

  public visible(isVisible: boolean): FieldOptionsBuilder {
    this.isVisible = isVisible;
    return this;
  }

  public view(viewOptions: { displayWhen: boolean }): FieldOptionsBuilder {
    if (viewOptions.displayWhen == false || viewOptions.displayWhen == true) {
      this.viewMode = viewOptions.displayWhen
        ? InputViewModeEnum.Display
        : InputViewModeEnum.Input;
    }

    return this;
  }

  /**
   * set disable value
   * @param disableOptions disabling options
   * @returns field options instance
   * @remarks disable if no disableOptions provided
   */
  public disable(disableOptions?: {
    whenIsVisible?: boolean;

    /**
     * works only if whenIsVisible property is null, it disables the field when the value of it is not equal to the given value
     * @remarks this value should come after the capture if capture method is used
     */
    whenValueIsNot?: any;
    isDisabled?: boolean;
    isDisabledToggleOnly?: boolean;

    whenBothWithOr?: { whenIsVisible: boolean; whenValueIsNot: any };
  }): FieldOptionsBuilder {
    if (disableOptions) {
      if (disableOptions.whenIsVisible !== undefined) {
        this.isDisabled = disableOptions.whenIsVisible === this.isVisible;
      } else if (disableOptions.whenValueIsNot !== undefined) {
        this.isDisabled = disableOptions.whenValueIsNot != this.value;
      } else if (disableOptions.isDisabled !== undefined) {
        this.isDisabled = disableOptions.isDisabled;
      } else if (disableOptions.whenBothWithOr) {
        this.isDisabled =
          disableOptions.whenBothWithOr.whenIsVisible === this.isVisible ||
          disableOptions.whenBothWithOr.whenValueIsNot != this.value;
      }

      this.isDisabledToggleOnly = disableOptions.isDisabledToggleOnly;
    } else {
      this.isDisabled = true;
    }

    return this;
  }

  public required(requireOptions?: {
    whenIsVisible?: boolean;
  }): FieldOptionsBuilder {
    if (requireOptions) {
      if (requireOptions.whenIsVisible !== undefined) {
        this.isRequired = requireOptions.whenIsVisible === this.isVisible;
      }
    } else {
      if (requireOptions == undefined) {
        this.isRequired = true;
      }
    }

    return this;
  }

  /**
   * set readonly value
   * @param readonlyOptions readonly options
   * @returns field options instance
   * @remarks readonly doesn't change the control state, add the [readonly] attribute in the html. readonly method marks readonly=true if no readonlyOptions provided
   */
  public readonly(readonlyOptions?: {
    whenIsVisible?: boolean;

    /**
     * works only if whenIsVisible property is null, it disables the field when the value of it is not equal to the given value
     * @remarks this value should come after the capture if capture method is used
     */
    whenValueIsNot?: any;
    isReadonly?: boolean;
    isDisabledToggleOnly?: boolean;

    whenBothWithOr?: { whenIsVisible: boolean; whenValueIsNot: any };
  }): FieldOptionsBuilder {
    if (readonlyOptions) {
      if (readonlyOptions.whenIsVisible !== undefined) {
        this.isReadonly = readonlyOptions.whenIsVisible === this.isVisible;
      } else if (readonlyOptions.whenValueIsNot !== undefined) {
        this.isReadonly = readonlyOptions.whenValueIsNot != this.value;
      } else if (readonlyOptions.isReadonly !== undefined) {
        this.isReadonly = readonlyOptions.isReadonly;
      } else if (readonlyOptions.whenBothWithOr) {
        this.isReadonly =
          readonlyOptions.whenBothWithOr.whenIsVisible === this.isVisible ||
          readonlyOptions.whenBothWithOr.whenValueIsNot != this.value;
      }

      this.isDisabledToggleOnly = readonlyOptions.isDisabledToggleOnly;
    } else {
      this.isReadonly = true;
    }

    return this;
  }

  /**
   * set the value if provided value is not undefined
   * @param value value to be set, if value is equal to undefined control value will not be affected,
   * null value though used to reset the control value
   * @returns field options instance
   */
  public capture(value: any): FieldOptionsBuilder {
    if (value === false || value === '' || value === null || value === 0) {
      this.value = value;
      return this;
    }

    if (value) this.value = value;

    return this;
  }

  public reset(resetOptions?: {
    isReset?: boolean;
    whenIsVisible?: boolean;
  }): FieldOptionsBuilder {
    if (resetOptions) {
      if (resetOptions.whenIsVisible != undefined) {
        this.isReset = this.isVisible == resetOptions.whenIsVisible;
      }
      if (resetOptions.isReset != undefined) {
        this.isReset = resetOptions.isReset;
      }
    } else {
      this.isReset = true;
    }

    return this;
  }
}
