import { Component, Injector, Input, SimpleChanges } from '@angular/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'bs-ng-checkbox',
  templateUrl: './bs-ng-checkbox.component.html',
  providers: [MakeProvider(BsNgCheckboxComponent)],
})
export class BsNgCheckboxComponent extends BsNgInputBaseComponent {
  @Input()
  set: any = true;

  public htmlLabel: string = '';

  public evaluatedHtmlLabel: string = '';

  @Input() url = '';

  constructor(translate: TranslateService, injector: Injector) {
    super(translate, injector);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  hasEqualTrueError() {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      !this.ngControl.control.errors.required &&
      this.ngControl.control.errors.equalTrue
    );
  }

  override assignTranslations(trs: any) {
    super.assignTranslations(trs);
    if (this.url) {
      this.htmlLabel = trs.htmlLabel;
      this.EvaluateHtmlLabel();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url?.currentValue != changes.url?.previousValue)
      this.EvaluateHtmlLabel();
  }

  private EvaluateHtmlLabel() {
    this.evaluatedHtmlLabel = this.htmlLabel.replace('{url}', this.url);
  }

  public get isHtmlLabel(): boolean {
    if (this.htmlLabel) {
      return true;
    }

    return false;
  }
}
