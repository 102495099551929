import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { EsTransactionBaseData } from 'src/app/core/models/shared/EsTransactionBaseData';
import { ExportBaseServiceRequest } from 'src/app/core/models/export/shared/ExportBaseServiceRequest';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';
import { FlowButtons } from './FlowButtons';
import { MainStepsConfigurations } from './MainStepsConfigurations';
import { EsFees } from 'src/app/core/models/shared/EsFees';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TemplateModeEnum } from './TemplateModeEnum';

export abstract class BaseServiceRequestStore {
  ///TODO: remove the procedure instance id & draft procedure instance id from configuration
  id?: number;
  draftRequestId?: number;
  templateId?: number;
  templateMode: TemplateModeEnum;
  procedureId?: number;

  form?: FormGroup;

  formRequestTemplate?: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
  });
  isShowRequestTemplateForm: boolean;

  fees$?: Subject<EsFees> = new Subject();

  isPaymentRequired?: boolean;

  //TODO: remove the nullable operator
  serviceDetails?: ExportBaseServiceRequest<EsTransactionBaseData>;
  serviceDetails$?: BehaviorSubject<any>;

  serviceConfig?: EsServiceRequestConfiguration;
  serviceConfig$?: BehaviorSubject<EsServiceRequestConfiguration>;

  serviceMetaData$?: BehaviorSubject<ServiceMetaData>;

  //TODO: remove the nullable operator
  flowButtons?: FlowButtons;

  flow$?: BehaviorSubject<MainStepsConfigurations>;
  flow?: MainStepsConfigurations;

  enableStep: boolean;
}
