<ng-container
  *ngIf="
    templateOptions.direction === 'horizontal';
    then horizontal;
    else default
  "
>
</ng-container>

<ng-template #default>
  <div
    class="form-group"
    [ngClass]="{
      'has-error has-danger':
        ngControl.touched && !ngControl.valid && !ngControl.disabled,
      'has-success': ngControl.touched && ngControl.valid
    }"
    #map
  >
    <label
      class="form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
    >
      {{ labelText }}
    </label>

    <ng-container *ngTemplateOutlet="searchBox"></ng-container>

    <agm-map
      [zoom]="zoom"
      [latitude]="latitude"
      [longitude]="longitude"
      [disableDefaultUI]="disableDefaultUI"
      [gestureHandling]="gestureHandling"
    >
      <agm-marker
        [latitude]="latitude"
        [longitude]="longitude"
        [markerDraggable]="markerDraggable"
        (dragEnd)="markerDragEnd($event)"
      >
      </agm-marker>

      <agm-circle
        [latitude]="latitude"
        [longitude]="longitude"
        [radius]="radius"
        [editable]="false"
        [fillColor]="circleColor"
      >
      </agm-circle>
    </agm-map>

    <div class="invalid-feedback" *ngIf="hasRequiredError()">
      {{ requiredMessage }}
    </div>

    <ng-container *ngIf="helpText && isShowHelpText; then helpTextTemplate">
    </ng-container>

    <div class="row my-2" *ngIf="showValueDisplay">
      <div class="col-6">
        <app-bs-display key="trMapLatitude" [valueText]="valueLatitude">
        </app-bs-display>
      </div>
      <div class="col-6">
        <app-bs-display key="trMapLongitude" [valueText]="valueLongitude">
        </app-bs-display>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #horizontal>
  <div class="form-group row">
    <label
      class="col-sm-3 form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
    >
      {{ labelText }}
    </label>
    <div class="col-sm-9">
      <ng-container *ngTemplateOutlet="searchBox"></ng-container>

      <agm-map
        [zoom]="zoom"
        [latitude]="latitude"
        [longitude]="longitude"
        [disableDefaultUI]="disableDefaultUI"
        [gestureHandling]="gestureHandling"
      >
        <agm-marker
          [latitude]="latitude"
          [longitude]="longitude"
          [markerDraggable]="markerDraggable"
          (dragEnd)="markerDragEnd($event)"
        >
        </agm-marker>

        <agm-circle
          [latitude]="latitude"
          [longitude]="longitude"
          [radius]="radius"
          [editable]="false"
          [fillColor]="circleColor"
        >
        </agm-circle>
      </agm-map>

      <div class="invalid-feedback" *ngIf="hasRequiredError()">
        {{ requiredMessage }}
      </div>

      <ng-container *ngIf="helpText && isShowHelpText; then helpTextTemplate">
      </ng-container>

      <div class="row my-2" *ngIf="showValueDisplay">
        <div class="col-6">
          <app-bs-display key="trMapLatitude" [valueText]="valueLatitude">
          </app-bs-display>
        </div>
        <div class="col-6">
          <app-bs-display key="trMapLongitude" [valueText]="valueLongitude">
          </app-bs-display>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #searchBox>
  <input
    [hidden]="showAddressTextSearch"
    type="text"
    [id]="identifier"
    [name]="name"
    class="form-control"
    [ngClass]="hasRequiredError() ? 'is-invalid' : touched ? 'is-valid' : ''"
    [(ngModel)]="address"
    #control="ngModel"
    [placeholder]="'trLocationSearch' | translate"
    (keydown.enter)="$event.preventDefault()"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    (change)="addressTextChange()"
    #search
  />
</ng-template>

<ng-template #helpTextTemplate>
  <small [id]="identifier + 'Help'" class="form-text text-muted">
    {{ helpText }}</small
  >
</ng-template>
