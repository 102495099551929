import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ValidationBaseOptions } from './ValidationBaseOptions';

export function moreThanOrEqualDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid =
      new Date(new Date(control.value).toDateString()) >=
      new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { moreThanDate: true };
  };
}

export function lessThanOrEqualDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid =
      new Date(new Date(control.value).toDateString()) <=
      new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { lessThanDate: true };
  };
}

export function moreThanDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid =
      new Date(new Date(control.value).toDateString()) >
      new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { moreThanDate: true };
  };
}

export function lessThanDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid =
      new Date(new Date(control.value).toDateString()) <
      new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { lessThanDate: true };
  };
}

export function optionalMoreThanOrEqualDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid =
      control.value == null ||
      new Date(new Date(control.value).toDateString()) >=
        new Date(new Date(date).toDateString());

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { moreThanDate: true };
  };
}

export function moreThanOrEqualDateDynamic(
  A_DateField: string,
  B_DateField: string,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    if (form.get(A_DateField).value && form.get(B_DateField).value) {
      const A_Date: Date = new Date(form.get(A_DateField).value);
      const B_Date: Date = new Date(form.get(B_DateField).value);
      const valid = A_Date.getTime() >= B_Date.getTime();
      const hasOptions = options && true;
      return valid
        ? null
        : hasOptions
        ? { [options.key]: options }
        : { moreThanDate: true };
    }
    return null;
  };
}

export function lessThanOrEqualDateDynamic(
  A_DateField: string,
  B_DateField: string,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    if (form.get(A_DateField).value && form.get(B_DateField).value) {
      const A_Date: Date = new Date(form.get(A_DateField).value);
      const B_Date: Date = new Date(form.get(B_DateField).value);
      const valid = A_Date.getTime() <= B_Date.getTime();
      const hasOptions = options && true;
      return valid
        ? null
        : hasOptions
        ? { [options.key]: options }
        : { lessThanDate: true };
    }
    return null;
  };
}

export function moreThanDateDynamic(
  A_DateField: string,
  B_DateField: string,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    if (form.get(A_DateField).value && form.get(B_DateField).value) {
      const A_Date: Date = new Date(form.get(A_DateField).value);
      const B_Date: Date = new Date(form.get(B_DateField).value);
      const valid = A_Date.getTime() > B_Date.getTime();
      const hasOptions = options && true;
      return valid
        ? null
        : hasOptions
        ? { [options.key]: options }
        : { moreThanDate: true };
    }
    return null;
  };
}

export function lessThanDateDynamic(
  A_DateField: string,
  B_DateField: string,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    if (form.get(A_DateField).value && form.get(B_DateField).value) {
      const A_Date: Date = new Date(form.get(A_DateField).value);
      const B_Date: Date = new Date(form.get(B_DateField).value);
      const valid = A_Date.getTime() < B_Date.getTime();
      const hasOptions = options && true;
      return valid
        ? null
        : hasOptions
        ? { [options.key]: options }
        : { lessThanDate: true };
    }
    return null;
  };
}
