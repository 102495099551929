<div class="menu mobile_menu menu-responsive">
  <div class="navigation">
    <a
      target="”_blank”"
      title="Close"
      class="close c-mobile-close-menu"
      data-toggle="close-main-menu"
      ><i class="fi-close-n-o"></i
    ></a>

    <ul class="primary-navigation p-0">
      <li class="first no-child">
        <a
          target="”_blank”"
          routerLink="/home"
          [queryParams]="{ dashboard: 'true' }"
          title="Home"
          class="first-level fi-home-n custom-tool-tip"
        ></a>
      </li>

      <li class="responsive-only">
        <a
          routerLink="/home"
          [queryParams]="{ dashboard: 'true' }"
          [innerText]="'MyRequests' | translate"
        >
        </a>
        <ul>
          <li class="no-child">
            <a
              routerLink="/services"
              target="”_blank”"
              [title]="'NewRequest' | translate"
              class="second-level"
              [innerHTML]="'NewRequest' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              routerLink="/home"
              target="”_blank”"
              [title]="'trSearchRequests' | translate"
              class="second-level"
              [innerHTML]="'trSearchRequests' | translate"
            ></a>
          </li>
        </ul>
      </li>

      <li class="responsive-only">
        <a href="#" [innerText]="'trImportantLinks' | translate"> </a>
        <ul>
          <li class="no-child">
            <a
              [href]="eServicesBaseLink + '/Account/FinancialTransactions.aspx'"
              target="”_blank”"
              [title]="'trFinancialTransactions' | translate"
              class="second-level"
              [innerHTML]="'trFinancialTransactions' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              [href]="eServicesBaseLink + '/Workspaces/MyInbox.aspx'"
              target="”_blank”"
              [title]="'trMyInbox' | translate"
              class="second-level"
              [innerHTML]="'trMyInbox' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              [href]="eServicesBaseLink + '/WorkSpaces/SearchEservices.aspx'"
              target="”_blank”"
              [title]="'trSearchInServiceAndMailBox' | translate"
              class="second-level"
              [innerHTML]="'trSearchInServiceAndMailBox' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              [href]="eServicesBaseLink + '/WorkSpaces/SearchEservices.aspx'"
              target="”_blank”"
              [title]="'trSearchInServiceAndMailBox' | translate"
              class="second-level"
              [innerHTML]="'trSearchInServiceAndMailBox' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              href="https://refund.adib.ae/portal/MOCCAE/portal/login"
              target="”_blank”"
              [title]="'trRefundPortal' | translate"
              class="second-level"
              [innerHTML]="'trRefundPortal' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              [href]="eServicesBaseLink + '/Account/ChangePassword.aspx'"
              target="”_blank”"
              [title]="'trChangePassword' | translate"
              class="second-level"
              [innerHTML]="'trChangePassword' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              [href]="
                eServicesBaseLink +
                '/Images/Manual/Mobile/user_manual_English.pdf'
              "
              target="”_blank”"
              [title]="'trDigitalServiceUserManual' | translate"
              class="second-level"
              [innerHTML]="'trDigitalServiceUserManual' | translate"
            ></a>
          </li>
        </ul>
      </li>

      <li class="no-child">
        <a
          target="”_blank”"
          [href]="mainWebsiteLink + lang + '/legislations.aspx'"
          [title]="'Legislations' | translate"
          class="first-level"
          [innerHTML]="'Legislations' | translate"
        ></a>
      </li>

      <li>
        <a
          target="”_blank”"
          [href]="mainWebsiteLink + lang + '/our-services.aspx'"
          [title]="'OurServices' | translate"
          class="first-level"
          [innerHTML]="'OurServices' | translate"
        ></a>
        <ul>
          <li class="first">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink + lang + '/our-services/service-directory.aspx'
              "
              [title]="'HomeHeaderServiceDirectory' | translate"
              class="second-level"
              data-rel="../Content/Generated/Thumbnails/2cd5c618_961x3d1wffffff.jpg"
              [innerHTML]="'HomeHeaderServiceDirectory' | translate"
            ></a>
            <ul>
              <li class="first">
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/our-services/service-directory.aspx?mainCategory=272'
                  "
                  [title]="
                    'HomeHeaderActivityProfessionalLicensing' | translate
                  "
                  [innerHTML]="
                    'HomeHeaderActivityProfessionalLicensing' | translate
                  "
                ></a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/our-services/service-directory.aspx?mainCategory=272'
                  "
                  [title]="'HomeHeaderExportImportServices' | translate"
                  [innerHTML]="'HomeHeaderExportImportServices' | translate"
                ></a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/our-services/service-directory.aspx?mainCategory=276'
                  "
                  [title]="'HomeHeaderRegistrationAccreditation' | translate"
                  [innerHTML]="
                    'HomeHeaderRegistrationAccreditation' | translate
                  "
                ></a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/our-services/service-directory.aspx?mainCategory=278'
                  "
                  [title]="
                    'HomeHeaderFishermenFarmersAnimalBreedersServices'
                      | translate
                  "
                  [innerHTML]="
                    'HomeHeaderFishermenFarmersAnimalBreedersServices'
                      | translate
                  "
                ></a>
              </li>
              <li class="last">
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/our-services/service-directory.aspx?mainCategory=280'
                  "
                  [title]="'HomeHeaderCites' | translate"
                  [innerHTML]="'HomeHeaderCites' | translate"
                ></a>
              </li>
            </ul>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/our-services/happiness_eq_englishaspx.aspx'
              "
              [title]="'HomeHeaderCustomerHappinessCharter' | translate"
              class="second-level"
              data-rel="../Content/Generated/Thumbnails/645e518d_961x3d1wffffff.jpg"
              [innerHTML]="'HomeHeaderCustomerHappinessCharter' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/customer-service-centers.aspx'"
              [title]="'HomeHeaderServiceCenters' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderServiceCenters' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/mawroothna-initiative.aspx'"
              [title]="'HomeHeaderMawrothonaInitiative' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderMawrothonaInitiative' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/our-services/seniar.aspx'"
              [title]="'HomeHeaderSeniarApplication' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderSeniarApplication' | translate"
            >
            </a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/our-services/integrated-management-system-policy.aspx'
              "
              [title]="'HomeHeaderIntegratedManagementSystemPolicy' | translate"
              class="second-level"
              [innerHTML]="
                'HomeHeaderIntegratedManagementSystemPolicy' | translate
              "
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/our-services/pesticides.aspx'"
              [title]="
                'HomeHeaderElectronicdirectoryofregisteredPesticides'
                  | translate
              "
              class="second-level"
              [innerHTML]="
                'HomeHeaderElectronicdirectoryofregisteredPesticides'
                  | translate
              "
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/our-services/approved-slaughterhouses.aspx'
              "
              [title]="'HomeHeaderApprovedSlaughterhouses' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderApprovedSlaughterhouses' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/our-services/list-of-approved-disapproved-countries-for-import.aspx'
              "
              [title]="
                'HomeHeaderListofApprovedCountiesforImportingLiveAnimals'
                  | translate
              "
              class="second-level"
              [innerHTML]="
                'HomeHeaderListofApprovedCountiesforImportingLiveAnimals'
                  | translate
              "
            >
            </a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink + lang + '/our-services/moccae-mobile-apps.aspx'
              "
              [title]="'HomeHeaderMOCCAEMobileApps' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderMOCCAEMobileApps' | translate"
            ></a>
          </li>
          <li class="clearfix"></li>
        </ul>
      </li>

      <li>
        <a
          target="”_blank”"
          [href]="
            mainWebsiteLink +
            lang +
            '/knowledge-and-statistics/biodiversity.aspx'
          "
          [title]="'Knowledge' | translate"
          class="first-level"
          [innerHTML]="'Knowledge' | translate"
        ></a>
        <ul>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/biodiversity.aspx'
              "
              [title]="'HomeHeaderBiodiversity' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderBiodiversity' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/air-quality.aspx'
              "
              [title]="'HomeHeaderAirquality' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderAirquality' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/food-safety.aspx'
              "
              [title]="'HomeHeaderFoodsafety' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderFoodsafety' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/marine-environment-and-fisheries-sustainability.aspx'
              "
              [title]="
                'HomeHeaderMarineEnvironmentFisheriesSustainability' | translate
              "
              class="second-level"
              [innerHTML]="
                'HomeHeaderMarineEnvironmentFisheriesSustainability' | translate
              "
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/wealth-and-animal-health.aspx'
              "
              [title]="'HomeHeaderAnimalDevelopmentHealth' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderAnimalDevelopmentHealth' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/agricultural-development.aspx'
              "
              [title]="'HomeHeaderAgricultureDevelopmentHealth' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderAgricultureDevelopmentHealth' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/climate-change.aspx'
              "
              [title]="'HomeHeaderClimateChange' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderClimateChange' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/green-economy.aspx'
              "
              [title]="'HomeHeaderEnvironmentandGreenDevelopment' | translate"
              class="second-level"
              [innerHTML]="
                'HomeHeaderEnvironmentandGreenDevelopment' | translate
              "
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/wastes-and-chemicals.aspx'
              "
              [title]="'HomeHeaderWastesandchemicals' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderWastesandchemicals' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/ecotourism.aspx'
              "
              [title]="'HomeHeaderEcotourism' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderEcotourism' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/agritourism.aspx'
              "
              [title]="'HomeHeaderAgritourism' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderAgritourism' | translate"
            ></a>
          </li>
          <li>
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/biosecurity.aspx'
              "
              [title]="'HomeHeaderBiosecurity' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderBiosecurity' | translate"
            ></a>
            <ul>
              <li class="first">
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/knowledge-and-statistics/biosecurity/committees.aspx'
                  "
                  [title]="
                    'HomeHeaderTheNationalCommitteeoftheBiosecurity' | translate
                  "
                  [innerHTML]="
                    'HomeHeaderTheNationalCommitteeoftheBiosecurity' | translate
                  "
                ></a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/knowledge-and-statistics/biosecurity/uae-fourth-biosecurity-conference.aspx'
                  "
                  [title]="
                    'HomeHeaderUAEFourthBiosecurityConference' | translate
                  "
                  [innerHTML]="
                    'HomeHeaderUAEFourthBiosecurityConference' | translate
                  "
                ></a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/knowledge-and-statistics/biosecurity/conferences.aspx'
                  "
                  [title]="
                    'HomeHeaderUAEThirdBiosecurityConference' | translate
                  "
                  [innerHTML]="
                    'HomeHeaderUAEThirdBiosecurityConference' | translate
                  "
                ></a>
              </li>
              <li class="last">
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/knowledge-and-statistics/biosecurity/uae-second-biosecurity-conference.aspx'
                  "
                  [title]="
                    'HomeHeaderUAESecondBiosecurityConference' | translate
                  "
                  [innerHTML]="
                    'HomeHeaderUAESecondBiosecurityConference' | translate
                  "
                ></a>
              </li>
            </ul>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/knowledge-and-statistics/biodiversity/terminology.aspx'
              "
              [title]="'HomeHeaderTerminology' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderTerminology' | translate"
            >
            </a>
          </li>
          <li class="clearfix"></li>
        </ul>
      </li>

      <li>
        <a
          target="”_blank”"
          [href]="mainWebsiteLink + lang + '/e-participation/social-media.aspx'"
          [title]="'DigitalParticipation' | translate"
          class="first-level"
          [innerHTML]="'DigitalParticipation' | translate"
        ></a>
        <ul>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink + lang + '/e-participation/social-media.aspx'
              "
              [title]="'HomeHeaderSocialMedia' | translate"
              class="second-level"
              data-rel="../Content/Generated/Thumbnails/34bd5296_961x3d1wffffff.jpg"
              [innerHTML]="'HomeHeaderSocialMedia' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/e-participation/social-media/e-participation-guidelines.aspx'
              "
              [title]="'HomeHeaderDigitalParticipationGuidelines' | translate"
              class="second-level"
              [innerHTML]="
                'HomeHeaderDigitalParticipationGuidelines' | translate
              "
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink + lang + '/e-participation/poll-results.aspx'
              "
              [title]="'HomeHeaderPollResults' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderPollResults' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/e-participation/communication-channels.aspx'
              "
              [title]="'HomeHeaderCommunicationChannels' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderCommunicationChannels' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/e-participation/social-media/survey.aspx'
              "
              [title]="'HomeHeaderSurvey' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderSurvey' | translate"
            >
            </a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/contact-minister.aspx'"
              [title]="'HomeHeaderContactMinister' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderContactMinister' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/e-participation/blogs.aspx'"
              [title]="'HomeHeaderBlogs' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderBlogs' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/e-participation/social-media/electronic-consultations.aspx'
              "
              [title]="'HomeHeaderDigitalConsultations' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderDigitalConsultations' | translate"
            ></a>
          </li>
          <li class="clearfix"></li>
        </ul>
      </li>

      <li>
        <a
          target="”_blank”"
          [href]="mainWebsiteLink + lang + '/media-center.aspx'"
          [title]="'MediaCenter' | translate"
          class="first-level"
          [innerHTML]="'MediaCenter' | translate"
        ></a>
        <ul>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/media-centre/moew-news.aspx'"
              [title]="'HomeHeaderMOCCAENews' | translate"
              class="second-level"
              data-rel="../Content/Generated/Thumbnails/83b5b489_961x3d1wffffff.jpg"
              [innerHTML]="'HomeHeaderMOCCAENews' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink + lang + '/media-centre/photo-gallery.aspx'
              "
              [title]="'HomeHeaderPhotoGallery' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderPhotoGallery' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink + lang + '/media-centre/video-gallery.aspx'
              "
              [title]="'HomeHeaderVisualLibrary' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderVisualLibrary' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink + lang + '/media-centre/download-logos.aspx'
              "
              [title]="'HomeHeaderDownloadLogos' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderDownloadLogos' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink + lang + '/media-centre/publications.aspx'
              "
              [title]="'HomeHeaderPublications' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderPublications' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink + lang + '/media-centre/audio-gallery.aspx'
              "
              [title]="'HomeHeaderAudioGallery' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderAudioGallery' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/media-centre/archive.aspx'"
              [title]="'Archive'"
              class="second-level"
              [innerHTML]="'HomeHeaderArchive' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="
                mainWebsiteLink +
                lang +
                '/media-centre/activities-and-events.aspx'
              "
              [title]="'HomeHeaderActivitiesandEvents' | translate"
              class="second-level"
              data-rel="../Content/Generated/Thumbnails/c4dcd83f_961x3d1wffffff.jpg"
              [innerHTML]="'HomeHeaderActivitiesandEvents' | translate"
            ></a>
          </li>
          <li class="last">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/media-center/initiatives.aspx'"
              [title]="'HomeHeaderInitiatives' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderInitiatives' | translate"
            ></a>
            <ul>
              <li class="first">
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/media-center/initiatives/reading-initiatives.aspx'
                  "
                  [title]="'HomeHeaderReadingInitiatives' | translate"
                  [innerHTML]="'HomeHeaderReadingInitiatives' | translate"
                ></a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/media-center/initiatives/volunteer-and-social-responsibility-initiatives.aspx'
                  "
                  [title]="
                    'HomeHeaderToleranceandSocialResponsibilityInitiatives'
                      | translate
                  "
                  [innerHTML]="
                    'HomeHeaderToleranceandSocialResponsibilityInitiatives'
                      | translate
                  "
                ></a>
              </li>
              <li class="last">
                <a
                  target="”_blank”"
                  [href]="
                    mainWebsiteLink +
                    lang +
                    '/media-center/initiatives/innovation-initiatives.aspx'
                  "
                  [title]="'HomeHeaderInnovationInitiatives' | translate"
                  [innerHTML]="'HomeHeaderInnovationInitiatives' | translate"
                ></a>
              </li>
            </ul>
          </li>
          <li class="clearfix"></li>
        </ul>
      </li>

      <li class="last">
        <a
          target="”_blank”"
          [href]="mainWebsiteLink + lang + '/open-data.aspx'"
          [title]="'OpenData' | translate"
          class="first-level"
          [innerHTML]="'OpenData' | translate"
        ></a>
        <ul>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/open-data.aspx'"
              title="MOCCAE Open Data"
              class="second-level"
              [innerHTML]="'HomeHeaderMOCCAEOpenData' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/open-data/gis-map.aspx'"
              title="GIS Map"
              class="second-level"
              data-rel="../Content/Generated/Thumbnails/4e3d4c83_961x3d1wffffff.jpg"
              [innerHTML]="'HomeHeaderGISMap' | translate"
              >}</a
            >
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/open-data/request-data.aspx'"
              [title]="'HomeHeaderRequestData' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderRequestData' | translate"
            ></a>
          </li>
          <li class="no-child">
            <a
              target="”_blank”"
              [href]="mainWebsiteLink + lang + '/open-data/propose-data.aspx'"
              [title]="'HomeHeaderSubmitData' | translate"
              class="second-level"
              [innerHTML]="'HomeHeaderSubmitData' | translate"
            ></a>
          </li>
          <li class="clearfix"></li>
        </ul>
      </li>
    </ul>

    <div class="tools">
      <ul class="utilities">
        <!-- <li class="search-li">
          <a
            href="javascript:void(0);"
            data-toggle="open-search"
            data-target="#search-menu"
            (click)="showSearchBar(true)"
            class="search-btn custom-tool-tip"
            [title]="'SearchInWebsite' | translate"
          >
            <i class="ico fi-search-n"></i>
            <span class="tip" [innerText]="'SearchInWebsite' | translate"></span
          ></a>
          <div class="menu-search" [class.show]="isShowSearchMenu">
            <a
              href="javascript:void(0);"
              title="Close"
              class="close"
              (click)="showSearchBar(false)"
              data-toggle="close-search"
              ><i class="fi-close-n-o"></i
            ></a>
            <div class="search-field-c" [formGroup]="searchByKeywordForm">
              <input
                autocomplete="off"
                type="text"
                class="search-field"
                [placeholder]="'SearchInWebsite' | translate"
                title="Search in Website"
                data-toggle="inputsearch"
                id="txtSearchWebsite"
                formControlName="keyword"
              />
              <ul
                *ngIf="searchByKeywordResult.length > 0"
                class="list-group list-group-flush m_scroller"
              >
                <li
                  (click)="handleSearchResultClick(result.textTitle)"
                  *ngFor="let result of searchByKeywordResult"
                  class="list-group-item cursor-pointer"
                  [innerText]="result.textTitle"
                ></li>
              </ul>
              <a
                target="”_blank”"
                (click)="search()"
                class="cursor-pointer"
                data-toggle="btndosearch"
                class="search-btn"
                title="Search"
              >
                <i class="ico fi-search-n"></i>
              </a>
            </div>
            <div class="keyword-suggestions-container-new">
              <div class="keyword-suggestions">
                <ul class="words-list add-shadow" data-toggle="words-list"></ul>
              </div>
            </div>
          </div>
        </li> -->

        <!-- <li class="hide-mobile">
          <a
            target="”_blank”"
            [href]="editProfileURL + '?lang=' + lang"
            [title]="'HeaderEditProfile' | translate"
            class="circle custom-tool-tip"
          >
            <i class="ico fi-user-n"></i>
          </a>
        </li> -->

        <li class="show-mobile">
          <a
            target="”_blank”"
            class="custom-tool-tip"
            routerLink="/home"
            [queryParams]="{ dashboard: 'true' }"
            title="Home"
          >
            <i class="ico fi-home-n"></i>
          </a>
        </li>

        <li>
          <a
            class="personalize custom-tool-tip"
            title="Personalize"
            data-toggle="modal"
            data-target="#colorpopup"
          >
            <i class="ico fi-settings-n"></i>
          </a>
        </li>

        <li class="show-mobile">
          <a
            target="”_blank”"
            *ngIf="isEnglish"
            (click)="changeLanguage('ar')"
            title="عربي"
            class="circle cursor-pointer custom-tool-tip"
          >
            <i class="ico fi-arabic-n"></i>
          </a>
          <a
            target="”_blank”"
            *ngIf="isArabic"
            (click)="changeLanguage('en')"
            title="English"
            class="circle cursor-pointer custom-tool-tip"
          >
            <i class="ico fi-english-n"></i>
          </a>
        </li>

        <li class="show-mobile">
          <a
            (click)="logout()"
            title="Logout from the account"
            class="circle custom-tool-tip"
          >
            <span class="icon-logout"></span>
          </a>
        </li>

        <li class="show-mobile">
          <a
            target="”_blank”"
            [href]="editProfileURL + '?lang=' + lang"
            title="Register"
            class="circle custom-tool-tip"
          >
            <span class="icon-edit-user"></span>
          </a>
        </li>

        <li class="hide-mobile">
          <a
            target="”_blank”"
            *ngIf="isEnglish"
            (click)="changeLanguage('ar')"
            title="عربي"
            class="circle cursor-pointer custom-tool-tip"
          >
            <i class="ico fi-arabic-n"></i>
          </a>
          <a
            target="”_blank”"
            *ngIf="isArabic"
            (click)="changeLanguage('en')"
            title="English"
            class="circle cursor-pointer custom-tool-tip"
          >
            <i class="ico fi-english-n"></i>
          </a>
        </li>

        <!-- <li class="hide-mobile">
          <a
            [title]="'MemberLogout' | translate"
            (click)="logout()"
            class="circle custom-tool-tip"
          >
            <span class="icon-logout"></span>
          </a>
        </li> -->
      </ul>

      <div style="display: none">
        <div class="aragement-vis" style="display: none">
          <ul>
            <li class="acc-hidden acc-border">
              <a
                target="”_blank”"
                class="toggle-display-icon toggle-display"
                title="Toggle display option"
              >
                <span class="icon icon-eye">Toggle display option</span>
              </a>
            </li>
            <li class="acc-hidden font-sizeinc-li">
              <a
                target="”_blank”"
                class="inc-font-size"
                title="Increase Font Size"
                ><span class="icon icon-util-increase-font-size"
                  >Increase Font Size</span
                ></a
              >
            </li>
            <li class="acc-hidden acc-font-border dec-font-size-li">
              <a
                target="”_blank”"
                class="dec-font-size"
                title="Decrease Font Size"
                ><span class="icon icon-util-decrease-font-size"
                  >Decrease Font Size</span
                ></a
              >
            </li>
            <li class="acc-hidden acc-close-border">
              <a target="”_blank”" class="close-btn" title=""
                ><span class="icon close-btn">X</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
